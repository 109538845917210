
import './assets/styles.css';

const Component = () => {

	return (
		<div className="FullScreenLoading"/>
	);
}

export default Component;