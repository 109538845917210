// Libraries
import { Link } from 'react-router-dom'


// Components
import Title from 'components/UI/Title'


const ReportsHomeScreen = () => {

	return (
		<>
			<Title title="Reports"/>
			Click on a report:<br/>
			<br/>
			<Link to="/reports/commission">Your Commission Report</Link>
		</>
	)

}

export default ReportsHomeScreen