// Libraries
import { useState, useEffect } from 'react';


// Components
import ListItem from './Item';
import Loading from 'components/UI/Loading';


// Other
import Api from 'other/Api';


const OrderPartsList = props => {

	// Props
	const { orderId } = props;


	// State
	const [ parts, setParts ] = useState([]);
	const [ loading, setLoading ] = useState(true);


	useEffect(() => {

		Api.orders.getParts(orderId, {
			params: {
				with: ['customer']
			},
			success: (response) => {
				setParts(response.data);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, [orderId])


	if ( loading )
	{
		return (<Loading message="Loading orders..."/>);
	}



	if ( parts !== null )
	{
		return (

			<>

				{parts.map((part) => {

					return <ListItem key={part.id} part={part}/>

				})}

			</>

		)
	}
	else
	{

		return (
			<>
				<ListItem/>
				<ListItem/>
				<ListItem/>
			</>
		)	
	}

}

export default OrderPartsList