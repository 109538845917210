// Libraries
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'


// Assets
import './styles.css'


const SummaryLine = props => {

	// We extract title and value here in case there is only 1 title and value,
	// it's a lot nicer doing title="blah" rather than title1="blah"
	let { title, value, title1, title2, value1, value2 } = props

	if ( title )
	{
		title1 = title
	}

	if ( value )
	{
		value1 = value
	}
	
	if ( title )
	{
		return (
			<div className="SummaryLine">
				<div className="SummaryLine__left">
					<div className="SummaryLine__title1">{title1}</div>
					<div className="SummaryLine__title2">{title2}</div>
				</div>
				<div className="SummaryLine__right">
					<div className="SummaryLine__value1">{value1}</div>
					<div className="SummaryLine__value2">{value2}</div>
				</div>
			</div>
		)
	}
	else
	{
		return (
			<div className="SummaryLine">
				<div className="SummaryLine__left">
					<div className="SummaryLine__title1"><Skeleton/></div>
					<div className="SummaryLine__title2"><Skeleton height={10}/></div>
				</div>
				<div className="SummaryLine__right">
					<div className="SummaryLine__value1"><Skeleton/></div>
					<div className="SummaryLine__value2"><Skeleton/></div>
				</div>
			</div>
		)
	}

}

SummaryLine.propTypes = {
	title: PropTypes.string,
	title1: PropTypes.string,
	title2: PropTypes.string
}

export default SummaryLine