// Packages
import { useState } from 'react'
import { Link } from "react-router-dom"
import { useAuth0 } from '@auth0/auth0-react';


// Components
import Hamburger from 'components/UI/Hamburger'
import Avatar from 'components/UI/Avatar'
import Navigation from 'components/UI/Navigation'
//import Alert from 'components/Alert'


// Assets
import './styles.css'


const Header = () => {

	// State
	const [ showNavigation, setShowNavigation ] = useState(false);

	/*
	Import variables from the Auth0 context
	*/
	const {
    	user
  	} = useAuth0();
	

	return (
		<>
			<header className="Header">

				<Hamburger onClick={() => {

					setShowNavigation(!showNavigation)

				}}/>

				<Link to="/" className="Header__logo"></Link>

				<Avatar firstname={user.given_name} lastname={user.family_name} picture={user['https://id.tropicskincare.com/picture_url']}/>

			</header>

			<Navigation active={showNavigation} onClose={() => {
				setShowNavigation(false)
			}}/>

		</>
	)
}

export default Header