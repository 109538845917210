// Packages
import { useState, useEffect } from 'react';


// Components
import FullScreenError from 'components/UI/FullScreenError';


// Other
import Api from 'other/Api';



const AppStatus = props => {


	// State
	const [ enabled, setEnabled ] = useState(true);
	const [ message, setMessage ] = useState('');


	useEffect(() => {

		/**
		 * Check that this App is enabled
		 */
		const checkStatus = async () => {
			Api.getAppStatus({
				success: (response) => {
					setEnabled(response.enabled);
					setMessage(response.message);
				},
				error: (error) => {
					setEnabled(true);
					setMessage('');
				},
				after: () => {
					// Run this check every 60 secs
					setTimeout(() => {
						checkStatus();
					}, 60 * 1000);
				}
			});
		}

		checkStatus();

	}, []);


	/**
	 * If this app is disabled, then show the offline screen
	 */
	if ( false === enabled )
	{
		return (
			<FullScreenError type="offline" title="We'll be right back" message={message}/>
		);
	}

	return props.children;
}

export default AppStatus;