// Packages
import { useState, useEffect } from 'react'
import dayjs from 'dayjs';


// Components
import Title from 'components/UI/Title'
import Loading from 'components/UI/Loading'


// Others
import Api from 'other/Api';


const CommissionReportScreen = () => {
	
	// State
	const [ sales, setSales ] = useState(null)





	useEffect(() => {
		
		Api.orders.list({

			success: (response) => {
				setSales(response.data)
			}

		}) 

		// eslint-disable-next-line
	}, [])


	return (
		<>
			<Title title="Commission Report"/>

			{ sales === null && 
				<Loading title="Report Loading" 
							description="Please wait"
							icon="loading.svg"/>
			}

			{ sales !== null &&
				<table className="report-table">
				<thead>
				<tr>
					<th>Date</th>
					<th>#</th>
					<th className="--right">Total</th>
					<th className="--right">Commission</th>
				</tr>
				</thead>
				<tbody>
				{sales.map((order) => {

					const date = dayjs(order.created_at).format('MMMM D YYYY')

					return (
						<tr key={order.id}>
							<td>{date}</td>
							<td>{order.id}</td>
							<td className="--right">&pound; {order.sub_total}</td>
							<td className="--right">&pound; {order.commission_earned}</td>
						</tr>
					)
				})
				}
				</tbody>
				</table>
			}

		</>
	)
}

export default CommissionReportScreen