// Libraries
import PropTypes from 'prop-types'


// Assets
import './styles.css'


const Title = props => {
	
	const { title, subtitle, action } = props

	return (
		<div className="Title">
			<div className="Title__header">
				<h1>{title}</h1>
				<div className="Title__header__action">
					{action()}
				</div>
			</div>
			<h2>{subtitle}</h2>
		</div>
	)

}

Title.defaultProps = {
	action: () => {}
}

Title.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	action: PropTypes.func
}

export default Title
