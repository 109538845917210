// Components
import Title from 'components/UI/Title'


const DashboardScreen = () => {
	
	return (
		<>
			<Title title="Your Dashboard" subtitle="All the things you care about will appear on the dashboard"/>

			<div>I am the dashboard - full of hope and promise</div>
		</>
	)
}


export default DashboardScreen