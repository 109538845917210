// Packages
import { Routes, Route } from 'react-router-dom';


// Screens
import ListScreen from 'screens/Orders/List';
import ViewScreen from 'screens/Orders/View';
import ViewPartScreen from 'screens/Orders/ViewPart';


// Layouts
import DefaultLayout from 'layouts/Default';


const OrderRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>

				<Route exact path="/:orderId" element={<ViewScreen/>}/>
				<Route exact path="/:orderId/parts/:partId" element={<ViewPartScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
				
			</Routes>
		</DefaultLayout>
	);
}


export default OrderRoutes;