

/*
 * Mimics the functionality of the PHP ucfirst function
 * Makes the first character of a string uppercase
 *
 * @param string
 * @return string
 */
const ucfirst = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}


/*
 * Add an 's' on the end of plural words
 * 
 * @param string word
 * @param int number
 */
const addS = (word, number) => {
    const s = number === 1 ? '' : 's'
    return word + s
}


/*
 * Format a currency string
 */
const currency = (value) => {
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    })
    
    return formatter.format(value);
}


/*
 * Replace parameters in a URL
 *
 * @param string
 * @param object/hash
 */
const replaceUrlParams = (string, params = {}) => {
    for (const name in params) {
        string = string.replace(':' + name, params[name])
    }

    return string
}


/*
 * Build a get URL string (&name=value etc) from a hash
 *
 * @param object/hash
 */
const buildUrlQueryString = (args) => {

    let queryString = ''

    if ( args )
    {
        for ( var index in args )
        {
            if ( Array.isArray(args[index]) )
            {
                for ( var i = 0; i < args[index].length; i++ )
                {
                    queryString += '&'+index+'[]=' + args[index][i]
                }
            }
            else
            {
                queryString += '&' + index + '=' + args[index]
            }
        }
    }

    return queryString
}



export { 
	ucfirst,
    addS,
    currency,
    replaceUrlParams,
    buildUrlQueryString
}