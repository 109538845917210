// Libraries
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"


// Assets
import './styles.css'


const EmptyState = props => {

	// Props
	const { title, description, icon, buttonLink, buttonLabel } = props


	// Dynamically load SVG icon
	const iconSvg = icon ? require('../../../assets/' + icon).default : null


	return (
		<div className="EmptyState">

			{ iconSvg &&
				<div className="EmptyState__icon">
					<img src={iconSvg} alt={title}/>
				</div>
			}

			<div className="EmptyState__title">
				{title}
			</div>

			{ description &&
				<div className="EmptyState__description">
					{description}
				</div>
			}

			{ buttonLabel &&
				<Link className="button button--small button--centered button--outlined" to={buttonLink}>{buttonLabel}</Link>
			}
			
		</div>
	)
	
}

EmptyState.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	icon: PropTypes.string,
	buttonLink: PropTypes.string,
	buttonLabel: PropTypes.string
}


export default EmptyState