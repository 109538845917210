// Packages
import React from 'react';
import { useState, useEffect } from "react";


// Components
import Loading from 'components/UI/Loading';
import ListItem from './Item';

//import Message from 'components/UI/Message';


// Other
import Api from 'other/Api';



const OrderList = () => 
{
    // State
    const [ orders, setOrders ] = useState([]);
    const [ isLoading, setLoading ] = useState(true);
    const [ errorMessage, setErrorMessage ] = useState('');


    useEffect(() => {

        Api.orders.list({
            params: {
                with: ['customer']
            },
            success: (response) => {
                setOrders(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, []);



    if ( isLoading )
    {
        return (<Loading message="Loading orders..."/>);
    }

    if ( errorMessage )
    {
        return(<>error: {errorMessage}</>);
        //return (<Message colour="red" text={errorMessage}/>);
    }



    return (
        <>

        {orders.map((order) => {

            return <ListItem key={order.id} order={order}/>

        })}

        </>
    );
}

export default OrderList;