// Components
import Header from 'components/UI/Header'


// Assets
import './styles.css'



const DefaultLayout = props => {

	return (
		<div className="DefaultLayout">
			<Header/>
			<div className="DefaultLayout__main">
			{props.children}
			</div>
		</div>
	)
}


export default DefaultLayout