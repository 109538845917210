// Libraries
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ucfirst, currency } from 'other/Utilities'


// Components
import Title from 'components/UI/Title'
import OrderPartsList from 'components/Lists/OrderParts'
import { Footer, FooterRow } from 'components/Footer'
import SummaryLine from 'components/UI/SummaryLine'


// Other
import Api from 'other/Api';


const OrderViewScreen = () => {
	
	// URL Params
	let { orderId } = useParams()


	// State
	const [order, setOrder] = useState(null);


	useEffect(() => {

		Api.orders.get(orderId, {
			success: (response) => {
				setOrder(response);
			}
		})

	}, [orderId])


	return (
		<>	
		{ order === null &&
			<>Loading....</>
		}

		{ order !== null &&
		
			<>
			<Title title={`${ucfirst(order.type)} Order #${order.id}`}/>

			<OrderPartsList orderId={orderId}/>
			</>
		}

		{ order !== null &&
			<Footer>
				<FooterRow>
					<SummaryLine title="Order Total" value={currency(order.total)} title2="Gift Allowance Earned" value2={currency(order.gift_allowance_earned)}/>
				</FooterRow>
			</Footer>
		}

		</>
	)
	
}


export default OrderViewScreen