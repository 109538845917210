// Packages
import PropTypes from 'prop-types';


// Assets
import './assets/styles.css';


const Component = props => {

	// Props
	const { type, title, message } = props;
	

	return (
		<div className="FullScreenError">
			<div className="FullScreenError__body">
				<div className={`FullScreenError__icon FullScreenError__icon--${type}`}></div>

				{ title &&
					<div className="FullScreenError__title">{title}</div>
				}

				{ message &&
					<div className="FullScreenError__message" style={{whiteSpace: 'pre-wrap'}}>{message}</div>
				}
			</div>
		</div>
	);
	
}


// Prop types
Component.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	type: PropTypes.oneOf(['access-denied', 'offline'])
};


export default Component;