// Libraries
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

// Assets
import './styles.css'


const OrderListItem = props => {
	
	const { order } = props

	if ( order )
	{
		const date = dayjs(order.created_at).format('MMMM D YYYY')

		const customers = order.customer_names.split(',')

		return (
			<Link to={`/orders/${order.id}`}>
				<div className={`OrderListItem OrderListItem--status-${order.status}`}>
					<div className="OrderListItem__header">
						<div>{date}</div>
						<div>Order # {order.id}</div>
						<div className={`status status--${order.status==='closed' ? 'green' : 'orange'}`}>{order.status}</div>
					</div>
					<div className="OrderListItem__body">
						<div className="OrderListItem__body__left">
							{customers.map((customerName, index) => {

								return (
									<div key={index}>
										{customerName}<br/>
									</div>
								)

							})}
						</div>
						<div className="OrderListItem__body__right">
							<div className="OrderListItem__price">
								&pound; {order.total}
							</div>
							<div className="OrderListItem__items">
								{order.number_of_items} items
							</div>
						</div>
					</div>
				</div>
			</Link>
		)
	}
	else
	{
		return (<>Loading</>)
	}
}

OrderListItem.propTypes = {
	order: PropTypes.object
}

export default OrderListItem