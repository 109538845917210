// Libraries
import { Link } from "react-router-dom"


// Components
import OrdersList from 'components/Lists/Orders'
import Title from 'components/UI/Title'
import EmptyState from 'components/UI/EmptyState'



const OrdersScreen = () => {

	/*
	 * Empty State
	 * - This is executed by <OrderList> if there are no orders to show
	 */
	const emptyState = () => {
		
		return (
			<EmptyState 
				title="Oooh, aaahh! Placing your first order?"
				description="It’s easy to place an order with Tropic,
					but feel free to reach out 
					to your manager if you need any help."
				icon="fireworks.svg"
				buttonLabel="Start your first order"
				buttonLink="/orders/new"/>
		)
	}


	const createOrderButton = () => {

		return (
			<Link to="/orders/new" className="icon icon--rocket">
				Start An Order
			</Link>
		)
		
	}


	return (
		<>

		<Title title="Orders" action={createOrderButton}/>

		<OrdersList emptyStateRender={emptyState}/>

		</>
	)
}


export default OrdersScreen