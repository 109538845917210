// Libraries
import { useState } from 'react'
import PropTypes from 'prop-types'


// Assets
import './styles.css'


const Accordian = props => {
	
	// State 
	const [ open, setOpen ] = useState(false)


	// Props
	const { title, closedContent, openContent } = props


	return (
		<div className={`Accordian ${open ? 'Accordian--open' : ''}`}>
			<div onClick={() => {

				setOpen(!open)

			}} className="Accordian__header">
				<div className="Accordian__header__title">
					{title}
				</div>
				{ ! open &&
					<div className="Accordian__header__content">
						{closedContent}
					</div>
				}
			</div>
			{ open &&
				<div className="Accordian__body">

				 {openContent()}

				</div>
			}

		</div>
	)
}	

Accordian.defaultProps = {
	title: "set 'title' prop"
}

Accordian.propTypes = {
	title: PropTypes.string.isRequired,
	closedContent: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func
	]),
	openContent: PropTypes.func.isRequired
}

export default Accordian