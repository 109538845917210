// Libraries
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'


// Assets
import './styles.css'


const OrderPartListItem = props => {
	
	// Props
	const { part } = props


	if ( part )
	{
		// Work out payment colour and text
		let paymentColour = 'red'
		let paymentText = 'Unknown'

		if ( 'ambassador' === part.payment_option)
		{
			paymentColour = 'green'
			paymentText = 'Will be paid by you'
		}
		else if ( 'customer' === part.payment_option )
		{
			paymentColour = 'orange'
			paymentText = 'Will be paid by the customer'
		}
	
		return (
			<Link to={`/orders/${part.order_id}/parts/${part.id}`}>
			<div className="OrderPartListItem">

				<div className="OrderPartListItem__left">
					<div className="OrderPartListItem__name">
						{part.customer.firstname} {part.customer.lastname}
					</div>
					<div className="OrderPartListItem__delivery grey-text small-text">
						Delivery to {part.delivery_option}
					</div>
					<div className="OrderPartListItem__payment small-text">
						<span className={`${paymentColour}-text`}>{paymentText}</span>
					</div>

				</div>

				<div className="OrderPartListItem__right">
					<div className="OrderPartListItem__price">
						£{part.total}
					</div>
					<div className="grey-text small-text">
						{part.number_of_items} items
					</div>
				</div>

			</div>
			</Link>
		)
	}
	else
	{
		return (
			<Skeleton height={100}/>
		)
	}
}

export default OrderPartListItem