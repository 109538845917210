// Packages
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';


// Components
import FullScreenLoading from 'components/UI/FullScreenLoading';
import FullScreenError from 'components/UI/FullScreenError';



const Auth = props => {

	/*
	Import variables/methods from the Auth0 context
	*/
	const {
    	isLoading,
    	isAuthenticated,
    	error,
    	loginWithRedirect,
    	getAccessTokenSilently
  	} = useAuth0();


  	// State
  	const [ accessToken, setAccessToken ] = useState(null);


  	/*
	Auth0 is processing - show full-screen loading spinner
  	*/	
  	if ( isLoading ) 
  	{
    	return <FullScreenLoading/>
  	}


  	/*	
  	An error has occurred with the Auth0 communication
  	*/
  	if ( error ) 
  	{
  		return <FullScreenError title="Authentication Error" type="access-denied" message={error.message}/>
  	}


  	if ( isAuthenticated ) 
	{
		/*
		The user is authenticated and has a valid Auth0 token
		*/

		// Get an access token for API requests
		const getToken = async () => {

			const accessToken = await getAccessTokenSilently();
			sessionStorage.setItem('access-token', accessToken);
			setAccessToken(accessToken);
	    }

	    getToken();


	    if ( accessToken !== null )
	    {
	    	return props.children;
	    }
	    else 
	    {
	    	return (<> Getting access token</>);
	    }
	}
	else 
	{
		/*
		The user needs to login, redirect to Auth0
		*/
		const returnTo = window.location.pathname + window.location.search + window.location.hash;
		loginWithRedirect({ appState: { returnTo: returnTo } });
	}
}

export default Auth;