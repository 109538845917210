// Libraries
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'


// Assets
import './styles.css'
import close from '../../../assets/close.svg'


const Navigation = props => {


	// Props
	let { active, onClose, items } = props
	

	return (
		<>
		<div className={`Navigation ${active ? 'Navigation--active' : ''}`}>

			<div className="Navigation__panel">
				
				<div className="Navigation__header">
					<div className="Navigation__header__emblem"/>
					<img onClick={() => {

						onClose()

					}} src={close} alt="Close Navigation" className="Navigation__header__close"/>
				</div>

				<ul className="Navigation__List">
				{items.map((item) => {

					return (
						<li key={item.url}><Link onClick={() => {

						onClose()

					}} to={item.url} className="Navigation__list__item__link">{item.label}</Link></li>
					)

				})}
				</ul>
			</div>

			<div className="Navigation__Overlay"/>

		</div>
		
		</>
	)
}



Navigation.defaultProps = {
	active: false,
	items: [
		{
			label: 'Dashboard',
			url: '/'
		},
		{
			label: 'Orders',
			url: '/orders'
		},
		{
			label: 'Reports',
			url: '/reports'
		}
	]
}

Navigation.propTypes = {
	onClose: PropTypes.func.isRequired,
	items: PropTypes.array
}

export default Navigation