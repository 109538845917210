// Packages
import { useEffect, useState } from 'react';
import { ucfirst, addS, currency } from 'other/Utilities';
import { Link, useParams } from 'react-router-dom';


// Components
import Title from 'components/UI/Title'
import Accordian from 'components/UI/Accordian'
import { Footer, FooterRow } from 'components/Footer'


// Other
import Api from 'other/Api';


// Assets
import './styles.css'


const ViewOrderPartScreen = () => {
	

	// URL Params
	let { orderId, partId } = useParams();


	// State
	const [order, setOrder] = useState(null);
	const [orderPart, setOrderPart] = useState(null);
	const [orderItems, setOrderItems] = useState([]);


	useEffect(() => {

		Api.orders.get(orderId, {
			success: (response) => {
				setOrder(response);
			}
		});

		Api.orderparts.get(partId, {
			params: {
				with: ['customer']
			},
			success: (response) => {
				setOrderPart(response);
			}
		});

		Api.orderparts.getItems(partId, {
			success: (response) => {
				setOrderItems(response.data);
			}
		});

	}, [orderId, partId])


	const orderStatusAccordianOpen = () => {
		return (
			<>
				I am open!
			</>
		)
	}



	const customerAccordianOpen = () => {
		return (
			<>
				<b>{orderPart.customer.firstname} {orderPart.customer.lastname}</b>
				<div style={{opacity: 0.7}}>
					{orderPart.customer.address1}<br/>
					{orderPart.customer.address2}<br/>
					{orderPart.customer.city}<br/>
					{orderPart.customer.county}<br/>
					{orderPart.customer.postcode}
				</div>
			</>
		)
	}



	const thingsToDoAccordianOpen = () => {
		return (
			<ul className="thingstodo">
				<li className="thingstodo__item">
					<Link to={`/orders/${orderId}/parts/${orderPart.id}/edit`} className="thingstodo__item__link thingstodo__item__link--edit">Edit Order Items</Link>
				</li>
				<li className="thingstodo__item">
					<a href="https://cdn.shopify.com/s/files/1/1959/4397/files/Returns_Form.pdf?v=1619003134" className="thingstodo__item__link thingstodo__item__link--orange thingstodo__item__link--return">Request Refund</a>
				</li>
				<li className="thingstodo__item">
					<Link to={`/orders/${orderId}/parts/${orderPart.id}/edit`} className="thingstodo__item__link thingstodo__item__link--customer">Edit Customer</Link>
				</li>
				<li className="thingstodo__item">
					<Link to={`/orders/${orderId}/parts/${orderPart.id}/cancel`} className="thingstodo__item__link thingstodo__item__link--red thingstodo__item__link--cancel">Cancel Order</Link>
				</li>
			</ul>
		)
	}


	const orderItemsAccordianOpen = () => {


		return (
			<table className="table">
			<tbody>
			{orderItems.map((item) => {

				return (
					<tr key={item.id}>
						<td>{item.quantity} x</td>
						<td>{item.name}</td>
						<td className="table__row__column--right">{currency(item.total_price)}</td>
					</tr>
				)

			})}
			</tbody>
			</table>
		)
	}


	


	if ( order && orderPart )
	{
		return (
			<>
			<Title 
				title={`${ucfirst(order.type)} Order #${orderId}`}
				subtitle={`${orderPart.customer.firstname} ${orderPart.customer.lastname}`}
			/>

			<Accordian 
				title="Order Status" 
				openContent={orderStatusAccordianOpen}
			/>

			<Accordian  
				title="Customer"
				closedContent={`${orderPart.customer.firstname} ${orderPart.customer.lastname}`}
				openContent={customerAccordianOpen}
			/>

			<Accordian 
				title="Ordered Items"
				closedContent={`${orderPart.number_of_items} ${addS('item', orderPart.number_of_items)} totalling ${currency(orderPart.sub_total)}`}
				openContent={orderItemsAccordianOpen}
			/>

			<Accordian 
				title="Things to do"
				openContent={thingsToDoAccordianOpen}
			/>

			<Footer>
				<FooterRow>
					<Link className="button button--back" to={`/orders/${orderPart.order_id}`}></Link>
				</FooterRow>
			</Footer>

			</>
		)
	}
	else
	{
		return (
			<>loading</>
		)
	}
}


export default ViewOrderPartScreen