// Libraries
import { useState } from 'react'
import PropTypes from 'prop-types'


// Assets
import './styles.css'


const Hamburger = props => {

	// Props
	const { onClick } = props
	

	// State
	const [ active, setActive ] = useState(false)


	return (
		<div className={`Hamburger ${active ? 'Hamburger--active' : ''}`} onClick={() => {

			setActive(!active)

			if ( onClick )
			{
				onClick()
			}

		}}>
			<div className="Hamburger__bar"></div>
			<div className="Hamburger__bar"></div>
			<div className="Hamburger__bar"></div>
		</div>
	)
}

Hamburger.propTypes = {
	onClick: PropTypes.func
}

export default Hamburger