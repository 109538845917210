/*

Main configuration file for this app

React only allows two environments natively (dev and prod), so
we have to manage our own env config :(

*/


/*
General Configuration
*/
const generalConfig = {
	ENVS: {
		DEV: "https://portal.dev.tropicskincare.com",
		STAGE: "https://portal.stage.tropicskincare.com",
		PROD: "https://portal.tropicskincare.com"
	},
	STAGE: process.env.REACT_APP_STAGE,
	AUTH0_SCOPES: "openid profile email"
}


/*
Environment-specific Configuration
*/
const environmentConfig = {

	/*
	Production Environment Configuration
	*/
	production: {
		API_URL: "https://api.portal.tropicskincare.com",
		AUTH0_DOMAIN: "id.tropicskincare.com",
		AUTH0_CLIENT_ID: "cYs1fAhuaDZfCQ5LRKTg0J0a0UWqhQyQ",

		APPSTATUS_URL: "https://appstatus.admin.tropicskincare.com/ambassador-portal.json",
	},
	

	/*
	Staging Environment Configuration
	*/
	staging: {
		API_URL: "https://api.portal.stage.tropicskincare.com",

		AUTH0_DOMAIN: "id.stage.tropicskincare.com",
		AUTH0_CLIENT_ID: "HqdikqKpzoG7shNf2pBdJIycSiANqDCc",

		APPSTATUS_URL: "https://appstatus.admin.stage.tropicskincare.com/ambassador-portal.json"
	},


	/*
	Development Environment Configuration
	*/
	development: {
		API_URL: "https://api.tropicskincare.dev",

		AUTH0_DOMAIN: "id.dev.tropicskincare.com",
		AUTH0_CLIENT_ID: "uFWFuVNK8VUFdhUt7nada7PbNApZZLdN",

		APPSTATUS_URL: "https://appstatus.admin.dev.tropicskincare.com/ambassador-portal.json"
	}

}

const config = {
	...generalConfig,
	...environmentConfig[process.env.REACT_APP_STAGE]
}

export default config;
