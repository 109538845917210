
// Assets
import './styles.css'

const Footer = props => {
	
	return (
		<div className="Footer">

			{props.children}

		</div>
	)

}


const FooterRow = props => {
	
	return (
		<div className="FooterRow">

			{props.children}

		</div>
	)

}

export default Footer
export { Footer, FooterRow }